import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import Container from '@material-ui/core/Container'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Comparador from '../../components/Comparador'
import Loading from '../../components/Loading'
import ErrorBoundary from '../../components/ErrorBoundary'

import * as S from './styles'
import { ditoTrack } from '../../helpers/dito'

const ComparadorResultadoPage = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const { language } = useI18next()
  const { t } = useTranslation()

  function trackComparador() {
    let num = 1
    const data = {}

    location.state !== null &&
      location.state.modelos !== undefined &&
      location.state.modelos.forEach((modelo) => {
        if (modelo.nome) {
          data[`modelo_${num++}`] = modelo.nome
        }
      })
    ditoTrack('comparou-produto', data)
  }

  useEffect(() => {
    if (location.state === null) {
      navigate(`/${language}/comparador/`)
    } else {
      setLoading(false)
    }
    trackComparador()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Layout>
      <SEO
        title={t('comparador_title_seo')}
        description={t('description_seo')}
      />
      <S.Bg page="resultado">
        <Container>
          <S.PageTitle>
            <strong>
              <Trans>Está em dúvida sobre o seu tênis ideal?</Trans>
            </strong>
            <br />
            <Trans>Faça um comparativo entre os seus favoritos.</Trans>
          </S.PageTitle>
          <ErrorBoundary>
            <Comparador modelosSelecionados={location.state.modelos} />
          </ErrorBoundary>
        </Container>
      </S.Bg>
    </Layout>
  )
}

ComparadorResultadoPage.defaultProps = {
  location: {},
}

ComparadorResultadoPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
}

export default ComparadorResultadoPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
